<template>
    <div class="grid">
        <div class="col-6">
            <div class="grid">
                <div class="col-12 text-xl">
                    <strong>Número: #{{ id }}</strong>
                </div>
                <div class="col-12">
                    <strong>Data:</strong> {{ data }}
                </div>
                <div class="col-12">
                    <strong>Cliente:</strong> {{ cliente }}
                </div>
                <div class="col-12">
                    <strong>Endereço:</strong> {{ endereco }}
                </div>
                <div class="col-12">
                    <strong>Telefone:</strong> {{ telefone }}
                </div>
                <div class="col-12">
                    <strong>Valor Total:</strong> {{ valor_total }}
                </div>
                <div class="col-12">
                    <strong>Forma de Pagamento:</strong> {{ forma_pagamento }}
                </div>
                <div class="col-12" v-if="observacao">
                    <strong>Observações:</strong> {{ observacao }}
                </div>
                <div class="col-12" v-if="frete">
                    <strong>Tipo de Entrega:</strong> {{ frete }}
                </div>
                <div class="col-12 mt-4 text-lg" v-if="statusEntrega">
                    <strong>Dados da Entrega:</strong>
                </div>
                <div class="col-12" v-if="statusEntrega">
                    <strong>Status:</strong> {{ statusEntrega }}
                </div>
                <div class="col-12" v-if="entregador">
                    <strong>Entregador:</strong> {{ entregador }}
                </div>
                <div class="col-12" v-if="dataAceite">
                    <strong>Aceitou em:</strong> {{ dataAceite }}
                </div>
                <div class="col-12" v-if="dataPronto">
                    <strong>Ficou pronto em:</strong> {{ dataPronto }}
                </div>
                <div class="col-12" v-if="dataFinal">
                    <strong>Finalizou em:</strong> {{ dataFinal }}
                </div>
            </div>
        </div>
        <div class="col-6">
            <div class="grid">
                <div class="col-12">
                    <ScrollPanel style="width: 100%; height: 383px; border-left: 1px solid #ccc; padding-left: 4%;">
                        <div id="produtos">
                            <div class="produto" v-for="p of produtos" :key="p.id">
                                <div class="grid">
                                    <div class="col-12 pb-0">
                                        <strong><i class="pi pi-tag"></i> {{ p.nome }}</strong>
                                    </div>
                                    <div class="col-6">
                                        <strong>Quant.: {{ p.qnt }}</strong>
                                    </div>
                                    <div class="col-6 text-right">
                                        <strong>Total: {{ $utils.formatCurrency(p.qnt * p.valor) }}</strong>
                                    </div>
                                </div>

                                <div v-for="pp of p.personalizacoes" :key="pp.id">
                                    <div v-for="ppi of pp.itens" :key="ppi.id">
                                        <div class="grid" v-if="ppi.qntSelecionado > 0">
                                            <div class="col-12 pb-0">
                                                <i class="pi pi-plus"></i> {{ ppi.nome }}
                                            </div>
                                            <div class="col-6 pt-0">
                                                Quant.: {{ ppi.qntSelecionado }}
                                            </div>
                                            <div class="col-6 pt-0 text-right">
                                                Total: {{ $utils.formatCurrency(ppi.qntSelecionado * ppi.valor) }}
                                            </div>
                                        </div>
                                    </div>
                                </div>

                            </div>
                        </div>
                    </ScrollPanel>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    props: {
        pedido: Object,
    },
    data() {
        return {

            "id": "",
            "data": "",
            "cliente": "",
            "endereco": "",
            "telefone": "",
            "observacao": "",
            "valor_total": "",
            "forma_pagamento": "",
            "produtos": {},
            "statusEntrega": "",
            "entregador": "",
            "frete": "",
            "comFrete": true,
            "dataAceite":"",
            "dataPronto":"",
            "dataFinal":""

        }
    },
    watch: {
        'pedido': {
            async handler() {
                
                //this.pedido.usuario = ret.data.data;
                let val = this.pedido;
                // console.log("IDDD",val.usuario.registro.id)
                // const ret_u = await this.$api.get('/usuarios/'+val.usuario.registro.id+"?with=registro");
                
                

                // console.log("EEEE",ret_u.data)
                // if(!ret_u.data.data?.registro) {
                //     return;
                // }
                // const ret = await this.$api.get('/pessoas/'+ret_u.data.data.registro.id+"?fields=nome,enderecoResumido,fone");
                // delete ret.data.data.id;
                // delete ret.data.data._id;
                // val.usuario = { ...val.usuario, ...ret.data.data };
                if(typeof val == "object" && val != null) {
                    //console.log(val.usuario)
                    this.id = val.numero;
                    this.data = this.$utils.formatDate(val.data);
                    this.cliente = val.usuario.registro.nome;
                    this.endereco = val.usuario.registro.enderecoResumido
                    this.telefone = Array.isArray(val.usuario.registro.contatos) && val.usuario.registro.contatos.length > 0 ? this.$utils.formatPhone(val.usuario.registro.contatos[0].telefone):"";
                    this.observacao = val.observacao;
                    this.valor_total = this.$utils.formatCurrency(val.valorTotal);
                    this.produtos = val.listaProdutos;
                    this.dataAceite = this.$utils.formatDate(val.dataAceite);
                    this.dataPronto = this.$utils.formatDate(val.dataPronto);
                    this.dataFinal = this.$utils.formatDate(val.dataFinal)

                    this.forma_pagamento = val.tipoPagamento + ' (' + val.pagamentoWeb + ') [' + (val.pagamentoConfirmado?"Recebido":"Pendente") + ']';

                    if(typeof val.statusEntrega != "undefined") {
                        this.statusEntrega = val.statusEntrega.status;
                        if(typeof val.statusEntrega.entregador != "undefined" && val.statusEntrega.entregador != null) {
                            this.entregador = val.statusEntrega.entregador.nome;
                        }
                    }

                    if(typeof val.tipoEntrega.id != "undefined") {
                        this.frete = val.tipoEntrega.nome;
                        this.comFrete = val.tipoEntrega.id.substr(0, 7) != "retirar";
                    } else {
                        this.comFrete = false;
                    }


                } else {

                    this.id = "";
                    this.data = "";
                    this.cliente = "";
                    this.endereco = "";
                    this.telefone = "";
                    this.observacao = "";
                    this.valor_total = "";
                    this.forma_pagamento = "";
                    this.produtos = {};
                    this.statusEntrega = "";
                    this.entregador = "";
                    this.frete = "";
                    this.comFrete = true;

                }                
            },
            deep: true,
            immediate: true,
        },
    },
}
</script>
<style scoped>

    :deep(#produtos .produto) {
        margin-bottom: 1rem;
        padding-bottom: 0.7rem;
        border-bottom: 1px solid #ccc;
    }

    :deep(#produtos .produto:last-child) {
        margin-bottom: 0;
        padding-bottom: 0;
        border-bottom: 0;
    }

</style>
