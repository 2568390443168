<template>

  <div class="grid">
    <div class="col-12 md:col-6">
      <h3><i class="pi pi-check-square text-2xl"></i> Pedidos Finalizados</h3>
    </div>
  </div>

  <Panel class="mt-2">

    <template #header>
      <div class="font-medium">
        Filtros
      </div>
    </template>

    <div class="grid p-fluid">

      <div class="p-field md:col-3">
        <label for="periodo">Período:</label>
        <Calendar class="mt-1" v-model="periodo" selectionMode="range" dateFormat="dd/mm/yy"/>
      </div>

      <div class="p-field md:col-3">
        <label for="periodo">Data:</label>
        <SelectButton class="mt-1" v-model="pasta_busca" :options="['Criação','Pagamento']" aria-labelledby="basic" />
      </div>

      <div class="p-field md:col-2">
        <Button
          id="filtrar"
          label="Filtrar"
          icon="pi pi-check"
          class="p-button-success mt-4"
          style="width: auto"
          @click="filtrar"
        ></Button>
      </div>

    </div>

  </Panel>

  <Panel class="mt-4">

    <template #header>
      <div class="font-medium">
      </div>
    </template>

    <div class="grid">
      <div class="col-12">

        <DataTable :value="pedidos"  :lazy="true" :loading="loading" responsiveLayout="scroll" v-model:expandedRows="pedidosExpandidos" @row-click="pedido_mostrar" selectionMode="single">
          
          <Column :expander="true" headerStyle="width: 3rem" />
          <Column class="col-numero" field="numero" header="Número"></Column>
          <Column class="col-data" field="data" header="Data">
            <template #body="slotProps">
                {{ $utils.formatDate(slotProps.data.data) }}
            </template>
          </Column>
          <Column class="col-cliente" field="usuario.nome" header="Cliente"></Column>
          <Column class="col-valor" field="valor" header="Valor">
            <template #body="slotProps">
              {{ $utils.formatCurrency(slotProps.data.valor) }}
            </template>
          </Column>

          <template #expansion="{data}">
            <DataTable :value="data.listaProdutos" :paginator="false">
              <Column class="col-produto-nome" field="nome" header="Produto"></Column>
              <Column class="col-produto-quantidade" field="qnt" header="Quantidade"></Column>
              <Column class="col-produto-valor" field="valor" header="Valor">
                <template #body="slotProps">
                  {{ $utils.formatCurrency(slotProps.data.valor) }}
                </template>
              </Column>
            </DataTable>
          </template>

          <template #empty>
            Nenhum pedido encontrado.
          </template>

          <template #footer>

            <div class="totalizadores totalizadores">
              <span>Pedidos: </span>
              <span>{{ totalizadores.pedidos }}</span>
            </div>

            <div class="totalizadores totalizadores">
              <span>Total: </span>
              <span>{{ $utils.formatCurrency(totalizadores.total) }}</span>
            </div>

          </template>

        </DataTable>
        
      </div>
    </div>

  </Panel>

  <Toast position="top-right" />

  <Dialog v-model:visible="pedido_edit_visible" style="width: 600px" :modal="true">
      <template #header>
        <div>
          <i class="pi pi-shopping-cart text-2xl"></i> <span class="text-2xl font-semibold">Pedido</span>
        </div>
      </template>

      <PedidoView :pedido="pedido_edit" />

    </Dialog>

</template>

<script>

import moment from 'moment-timezone';
import PedidoView from "./pedidos/PedidoView.vue";

export default {
  data() {
    return {
      //Controle de Apresentação
      loading: false,
      pedido_edit_visible:false,
      pedidosExpandidos: [],
      pasta_busca:"Criação",

      // Campos do Formulário
      periodo: null,
      pedido_edit:null,
      loja:null,

      // Dados cadastrados
      pedidos: [],
      totalizadores: {
        pedidos: 0,
        total: 0,
      },
    };
  },

  watch: {

    pedidos: function(dados) {

      this.totalizadores = {
        pedidos: 0,
        total: 0,
      };

      for(const d of dados) {

        if(typeof d.valorVenda != "undefined")
          d.valor = d.valorVenda;

        this.totalizadores.total += d.valor;
        this.totalizadores.pedidos++;
      }

    },
    '$auth.usuario.loja': {
      handler: function (val) {
        this.loja = this.$utils.getStdObject(val);
      },
      deep: true,
      immediate: true
    },
  },

  methods: {

    async filtrar() {

      let data_inicial;
      if(this.periodo != null && this.periodo[0] != null) {
        data_inicial = moment(this.periodo[0]).tz("America/Sao_Paulo").format("YYYY-MM-DD");
        
        
      } else {
          
        this.$toast.add({
          severity: "error",
          summary: "ERRO!",
          detail: "A data inicial precisa ser informada!",
          life: 5000,
        });

        return;
      }

      let data_final;
      if(this.periodo[1] != null) {
        data_final = moment(this.periodo[1]).tz("America/Sao_Paulo").format("YYYY-MM-DD");
       
      } else {
        data_final = data_inicial;
      }

      let query = "?status=Finalizado&loja.id="+this.loja.id

      if(this.pasta_busca == "Criação"){
        query += "&data=~"+data_inicial+","+data_final;
      }else{
        query += "&pagamentoConfirmacaoData=~"+data_inicial+","+data_final;
      }

      this.loading = true;

      

      const response = await this.$api.get('/pedidos' + query);
      const data = response.data;

      if(data.success) {

        this.pedidos = data.data;

      } else {

        console.log(data);

        this.$toast.add({
          severity: "error",
          summary: "ERRO!",
          detail: data.error.message,
          life: 5000,
        });

      }

      this.loading = false;
        
    },
    pedido_mostrar(pedido) {
      //console.log(pedido.data)
      this.pedido_edit = pedido.data;
      this.pedido_edit_visible = true;
      //console.log(this.pedido_edit);

    },

  },
  components: { 
    PedidoView
  },
  
};
</script>

<style lang="scss" scoped>

  .totalizadores {
    width: 20%;
    display: inline-block;
  }

</style>